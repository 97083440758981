import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

import Layout from "../components/Layout";
import CustomLink from "../components/CustomLink";
import ContactFormContent from "../components/ContactFormContent";


// eslint-disable-next-line
export const FormationContactForm = () => {
  return (
    <form className="flex-column alitems-stretch" 
          name="demande-formation"
          method="post" 
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/contact-confirmation/">
        <input type="hidden" name="form-name" value="demande-formation" />
        <input type="hidden" name="bot-field" />

        <h3 className="title-5 mgb-s
                       m-left-text pdr-m">
          Demande de formation et études
        </h3>

        <ContactFormContent formName="assistance-page-contact" color="light"/>
        <div className="full-width flex-row justc-center mgt-m">
          <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY}/>
        </div>
      </form>
  )
}



class AssistanceIntro extends React.Component {

  render() {
    const section  = this.props.page.frontmatter;

    return (
      <div>
        <div className="assistance-cover">
          <img src="/img/assistance-cover.svg" alt="cover"/>
        </div>
        <div>

          <div className="content-wrapper small assistance-content">
            <div className="l-half-width">
              <h1 className="section-title left title-3 text-medium s-mgt-s">{section.title}</h1>
            </div>
          </div>

          <div className="content-wrapper small flex-row justc-space-b alitems-center padding-vertical-l
                          m-padding-vertical-m s-flex-column s-alitems-start">
            <div className="half-width pic-radius-wrapper s-full-width">
              <img className="block-image" src={section.assistanceSection.image} alt="assistance" />
            </div>
            <div className="half-width s-full-width s-mgt-s">
              <h3 className="title-4 text-light bold-weight mgb-s">{section.assistanceSection.title}</h3>
              <ReactMarkdown children={section.assistanceSection.textContent} className="arrow-list paragraph-spacing mgb-m" />
              <Link className="cta fill blue"
                to="/contact/"
                state={{ contactType: "assistance-selected" }}>
                {section.assistanceSection.callToAction.text}
              </Link>
            </div>
          </div>

          <div className="content-wrapper small flex-row justc-space-b alitems-center mgt-xl
                          s-flex-column-reverse s-alitems-start">
            <div className="half-width s-full-width s-mgt-s">
              <h3 className="title-4 text-light bold-weight mgb-s">{section.blogSection.title}</h3>
              <ReactMarkdown children={section.blogSection.textContent} className="arrow-list paragraph-spacing mgb-m" />
              <CustomLink linkURL={section.blogSection.callToAction.url}
                          linkType="external-blank"
                          className="cta fill blue">
                {section.blogSection.callToAction.text}
              </CustomLink>
            </div>
            <div className="half-width s-full-width">
              <img className="block-image" src={section.blogSection.image} alt="assistance" />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

// eslint-disable-next-line
export const AssistancePageTemplate = ({ page }) => {
  return (
    <div>
      <AssistanceIntro page={page}/>
    </div>
  )
}


const AssistancePage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
  } = page; 

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <AssistancePageTemplate page={page}/>
    </Layout>
  );
};

AssistancePage.propTypes = {
  data: PropTypes.object.isRequired,
};


export default AssistancePage;

export const pageQuery = graphql`
  query AssistancePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        assistanceSection {
          image
          title
          textContent
          callToAction {
            text
          }
        }
        blogSection {
          image
          title
          textContent
          callToAction {
            text
            url
          }
        }
        formationSection {
          image
          title
          textContent
          callToAction {
            text
          }
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
